/* eslint-disable prettier/prettier */
/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable lingui/text-restrictions */
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const AuthenticCoverLetterPlan = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    {
      question: "1. A cover letter is NOT for recruiters",
      answer: (
        <>
          <p className="mb-2">
            The terms “recruiter” and “hiring manager” are not interchangeable.
          </p>
          <p className="mb-2">
            Recruiters source candidates and vet incoming applicants for an organization. Hiring
            managers are the ones adding someone new to their team. Recruiters are part of human
            resources. The hiring manager is likely who you'd report to if hired.
          </p>
          <p className="mb-2">
            Recruiters are unlikely to read your cover letter at all. They know exactly what they're
            looking for in a resume.
          </p>
          <blockquote className="mb-2">
            “I can't think of a single applicant tracking system I've used where the cover letter is
            shown before the resume,” recruiter and LinkedIn Top Voice Rhona Barnett-Pierce told me.
            “I've made a decision before I even reach the cover letter based on the resume and
            application questions.”
          </blockquote>
          <p className="mb-2">
            Hiring managers also jump straight to your resume, but because they are exposed to fewer
            applicants than recruiters, they are more likely to read your cover letter before an
            interview.
          </p>
        </>
      ),
    },
    {
      question: "2. A cover letter is NOT your introduction",
      answer: (
        <>
          <p className="mb-2">
            The reason a hiring manager will read your cover letter second and a recruiter will skip
            it altogether is simple: your resume serves as a greater professional introduction than
            your cover letter ever will. It includes your name, location, education, and work
            history.
          </p>
          <p className="mb-2">
            Don't let the word “cover” throw you off. There is no need to reintroduce yourself in a
            modern cover letter. The reader already knows.
          </p>
          <p className="mb-2">
            Write your cover letter knowing that your resume has already been read.
          </p>
        </>
      ),
    },
    {
      question: "3. A great cover letter is NOT a summary, it's an add-on",
      answer: (
        <>
          <p className="mb-2">
            Because resumes get read first, cover letters do not need to summarize the contents of a
            resume.
          </p>
          <blockquote className="mb-2">
            “I rarely read cover letters and the few that I've read haven't changed my mind because
            they typically just reiterate what was already on the resume,” continued Barnett-Pierce.
          </blockquote>
          <p className="mb-2">
            Recruiters and hiring managers should never read your cover letter and think, “I know
            this already.” That's a waste of their time and yours.
          </p>
          <p className="mb-2">
            Instead, use your cover letter as an opportunity to add credibility to your skills and
            achievements. You can't do this by re-listing the hits from your resume.
          </p>
          <p className="mb-2">
            This guide will show you how to add heft to your résumé's skills and achievements
            through evidence, context, and helpful details.
          </p>
        </>
      ),
    },
    {
      question: "4. A generic cover letter is NOT “better than nothing",
      answer: (
        <>
          <p className="mb-2">
            There's a belief that a cover letter requirement is primarily meant to reduce the number
            of applicants. From the hiring side, the thinking goes that applicants who take the time
            to write a cover letter are more motivated and committed than those who don't.
          </p>
          <p className="mb-2">
            But access to cover letter templates and AI writing assistants made it very easy for
            anyone to meet the minimum cover letter requirements with a semi-customized cover
            letter.
          </p>
          <p className="mb-2">
            Because of this, cover letter requirements no longer scare away the busy, unmotivated,
            and lazy.
          </p>
          <p className="mb-2">
            Employers are getting “more than double the number of candidates per job” since the
            introduction of ChatGPT, according to a report by Financial Times. As one manager told
            them, “We're definitely seeing higher volume and lower quality, which means it is harder
            to sift through.”
          </p>
          <p className="mb-2">
            An authentic cover letter expresses things not found in your resume—things ChatGPT
            doesn't know about you. This guide will help you write an authentic companion piece to
            your resume.
          </p>
          <p className="mb-2">
            <strong>Note:</strong> Cover letters are required less often than you think. Before
            writing one, see what it says in the job ad. Many online job applications include a
            cover letter upload field even if the job ad doesn't specifically request one. Save your
            time and resources by only writing a cover letter when required.
          </p>
        </>
      ),
    },
  ];

  const toggleFAQ = (index: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section id="blog1">
      {faqs.map((faq, index) => (
        <div key={index} className="mt-4">
          <div
            className="flex cursor-pointer items-center justify-between text-[#111928]"
            onClick={() => {
              toggleFAQ(index);
            }}
          >
            <h3 className="flex-1 text-sm font-semibold">{faq.question}</h3>
            {/* Render arrow icon based on the expanded state */}
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <span className="text-gray-600 ">
              {expandedIndex === index ? (
                <i className="ph ph-caret-up size-6" /> // Caret up icon
              ) : (
                <i className="ph ph-caret-down size-6" /> // Caret down icon
              )}
            </span>
          </div>
          <div className="mt-2 border-b border-gray-300" /> {/* Bottom border line */}
          {expandedIndex === index && <p className="mt-2 text-xs text-[#111928]">{faq.answer}</p>}
        </div>
      ))}
    </section>
  );
};

export default AuthenticCoverLetterPlan;
