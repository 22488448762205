/* eslint-disable prettier/prettier */
/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable lingui/text-restrictions */
import { Trans } from "@lingui/macro";
import React from "react";

import AuthenticCoverLetterPlan from "./_components/authentic-cover-letter-plan";

const Blog1Section = () => {
  return (
    <div className="mx-auto my-10 max-w-2xl rounded-lg bg-gray-100 p-6 shadow-md">
      <h1 className="mb-4 text-3xl font-semibold text-[#0182A9]">
        How to Write a Truly Authentic Cover Letter
      </h1>
      <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
        {" "}
        The cover letter as we know it goes back to the Second Industrial Revolution, but it wasn't
        until the early 1980s recession that the art of cover letter writing came into its own.
      </div>
      <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
        Cover letters are used differently during your job search today than they were back then,
        but most covr letter advice circulating now is stuck squarely in the 1900s. This is the
        training received by generations of job seekers and ChatGPT alike, resulting in cover
        letters that are lazy and ineffective.
      </div>
      <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
        In order to write an authentic cover letter—one that is effective and uniquely yours—here
        are four things you'll need to relearn about cover letters before we dive in:{" "}
      </div>
      <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
        <AuthenticCoverLetterPlan />
      </div>
      <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
        <h2 className="mb-2 text-3xl font-bold text-[#0182A9]">Cover Letter Introduction</h2>
        <h3 className="mb-10 text-2xl font-bold text-[#0182A9]">Salutation</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Unless you're applying to a particularly stuffy organization, drop the formalities. No “To
          whom it may concern,” no “Dear sir or madame.” Write like you'd normally address an email
          to a company.
        </div>
        <div className="my-10 text-lg font-normal leading-normal text-[#3F444F]">
          I imagine it would look like this:
        </div>
        <ul className="list-inside list-disc space-y-2 text-gray-700">
          <li>Hi,</li>
          <li>Hello,</li>
          <li>Hi there,</li>
          <li>Hello [Company Name],</li>
          <li>Dear [Company Name] hiring team,</li>
          <li>Dear hiring manager,</li>
        </ul>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Note: A common piece of advice is to track down the name of the hiring manager so you can
          address your cover letter to them personally, e.g. “Dear Rosa Martinez.” But be
          careful-what once showed initiative could give someone the creeps today.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Use the hiring manager's name if it's readily available in the job ad, LinkedIn updates,
          or reliable intel from someone in your professional network. If you find yourself
          cyberstalking company employees trying to find the hiring manager's name, stop. You've
          gone too far. A simple “hello” will do.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Furthermore, guessing the wrong person could lead you to address a recruiter, the wrong
          middle manager, or someone higher or lower than the hiring manager in the organizational
          hierarchy. This detracts from your cover letter.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Avoid this mistake by keeping things simple. Hello!
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Confirm your application</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Get right into it after the salutation. No “Hi, my name is…” They already know.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Start by mentioning the exact job title you're applying for as well as how you applied.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Recruiters know exactly what job you applied for and where your application came from
          thanks to their applicant tracking system, but cover letters are targeted at hiring
          managers. Hiring managers are less likely to know the finer details of your application
          and may need to get their bearings.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          This is also an opportunity to show your interest in the company or enthusiasm for the
          job.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">Examples:</div>
        <ul className="list-inside list-disc space-y-2 text-gray-700">
          <li>
            “Thank you for considering my application for the [Job Title] opening as found on [Job
            Source].”
          </li>
          <li>
            “I couldn't apply fast enough after seeing the [Job Title] opening at [Company Name] on
            [Job Source].”
          </li>
          <li>
            “Rarely have I felt so confident ticking the boxes of a job ad than the [Job Title]
            opening on [Job Source].”
          </li>
        </ul>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Make a personal connection</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          The next section is where most applicants begin rehashing their top qualifications.
          They'll provide an overview of their industry experience, list a few top skills, and
          mention an achievement or two—all things easily gleaned from their resume.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          But your resume is only a page or two long, right? Does the hiring manager really need
          that condensed down to a single paragraph in your cover letter? Probably not.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          An authentic personal connection with the company or team will serve as a more effective
          hook. There are a few ways to go about this if you're stuck.
        </div>
        <ul className="list-inside list-disc space-y-2 text-gray-700">
          <li>
            Share your experience with the company or their core product(s), i.e. your experience as
            a customer or consumer
          </li>
          <li>
            Share what you learned while researching or trying the company's product(s) for the
            first time
          </li>
          <li>Mention relationships or familiarity with key people within the company</li>
          <li>Pick out something from the company's employment reviews that excites you</li>
          <li>Share your genuine reaction to the company's stated mission</li>
          <li>
            Demonstrate understanding of some company or departmental challenge, even if it's just
            an educated guess
          </li>
          <li>List the top reasons you want this job more than other jobs</li>
        </ul>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Keep it short. 1-3 sentences will be enough.
        </div>

        <h2 className="mb-2 mt-10 text-3xl font-bold text-[#0182A9]">Cover Letter Body</h2>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          The resume tends to contain objective facts about your career. The cover letter body is
          where you can be a little more subjective and explain the peculiar details of your work.
          It's the perfect place to add valuable information to what’s already on your resume.
        </div>
        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Context</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Some context that might be valuable to hiring managers include:
        </div>
        <ul className="list-inside list-disc space-y-2 text-gray-700">
          <li>The reason you're looking for something new</li>
          <li>Why you're the perfect fit for the job</li>
          <li>Why you're a better fit for the job than it seems</li>
          <li>Additional qualifications and certifications you've earned related to the job</li>
          <li>Stories behind top accomplishments or successes</li>
        </ul>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Sharing a bit about the methods or tools used to achieve measurable results or
          accomplishments from your resume can give the hiring manager more confidence in your
          ability to repeat past successes.
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Workstyle and superpowers</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Ultimately, the hiring manager reading your cover letter wants to know if you'll be a good
          fit on their team. One of the best ways for them to do that is to understand how you work.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Explain how you start a new project or identify tasks to work on. Describe your preferred
          industry software or tech stack. Let them know which technology or methods you rely on to
          complete your work.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Describe your professional superpowers, or the unique things you do that allow you to be
          successful. These are often soft interpersonal skills or unique combinations of hard
          skills.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          One novel way to stand out with your cover letter would be to attach a personality
          snapshot like the one available from Working Life:{" "}
          <iframe
            className="max-w-sm border-none"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UTr0VUuWlS4?si=bgaeYwJBMy7PMCti"
            title="YouTube video player"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Anecdotes</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          The cover letter body is a great place to leave additional stories about your career that
          are too wordy or unconventional for a resume. This is especially helpful if your resume
          lacks measurable results. For example, you could include:
        </div>
        <ul className="list-inside list-disc space-y-2 text-gray-700">
          <li>Results from a formal employee review</li>
          <li>Other positive things managers and co-workers have said about you</li>
          <li>The unique ways you use tools mentioned in the job description</li>
          <li>Additional achievements relevant to the job opening</li>
        </ul>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          For example, it might not fit your resume to include something like “I was the top scoring
          employee in my department during internal 360-Degree Feedback reviews,” but it would be
          right at home on a cover letter.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Include ways you were described by your manager. If they ever mentioned something about
          your skills, communication, creativity, ideas, efficiency, accuracy, friendliness, or
          reliability, consider adding it here as long as it's relevant to the job opening.
        </div>

        <h2 className="mb-2 mt-10 text-3xl font-bold text-[#0182A9]">Cover Letter Conclusion</h2>
        <h3 className="mb-10 text-2xl font-bold text-[#0182A9]">Ready, set, go!</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          To conclude your resume, mention how highly motivated you are for the job and that you're
          ready to hit the ground running. Emphasize your familiarity with the technology mentioned
          in the job listing. Share that you're already thinking about the job and generating ideas.
          Be eager to hear back.
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Relevant links</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Include links to your portfolio or LinkedIn profile, if relevant, even if it's already on
          your resume. Make these links convenient.
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">
          Contact info and availability
        </h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          There's no reason not to make your contact information extremely handy. Even though it
          will already be part of your application, include your phone number, email address, and
          general availability here.
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Send-off</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Sign off like you're part of the team sending an important intercompany email. “Sincerely”
          is fine. I'm a “Best regards” kind of guy. If you're a “Cheers” person, be a “Cheers”
          person. Don't overthink this one.
        </div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Following these steps should help you create a cover letter that stands out for its
          authenticity in an era of cover letter templates and AI slop.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          This type of cover letter will look different than the standard two- or three-paragraph
          cover letter templates. An authentic cover letter will likely run a full page, maybe a
          page and a half. That's OK, because your content will be much more compelling than almost
          every other cover letter received.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Naturally, it's more time consuming to craft an authentic cover letter than a generic one.
          With that in mind, consider saving this method for your top applications-the ones you're
          best qualified for and the ones you want the most.
        </div>

        <h3 className="mb-2 mt-10 text-2xl font-bold text-[#0182A9]">Links</h3>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <ul className="list-inside list-disc space-y-2 text-gray-700">
            <li>
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="https://www.youtube.com/watch?v=UTr0VUuWlS4"
                rel="noreferrer"
              >
                Working Life personality snapshot cover letter add-on
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="https://hbr.org/podcast/2025/01/how-to-write-a-cover-letter-that-gets-noticed"
                rel="noreferrer"
              >
                “How to Write a Cover Letter That Sounds Like You (and Gets Noticed),”
              </a>{" "}
              podcast transcript from Harvard Business Review
            </li>
            <li>
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="https://www.linkedin.com/pulse/cover-letter-your-comprehensive-guide-from-recruiting"
                rel="noreferrer"
              >
                “Cover Letter: Your Comprehensive Guide from Recruiting”
              </a>{" "}
              by Kristen Fife on LinkedIn
            </li>
            <li>
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="https://www.thecut.com/2025/01/how-to-write-a-cover-letter-that-will-get-you-a-job"
                rel="noreferrer"
              >
                “How to Write a Cover Letter That Will Get You a Job”
              </a>{" "}
              by Alison Green for The Cut
            </li>
            <li>
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="https://www.welcome-to-the-jungle.com/ai-generated-cover-letters"
                rel="noreferrer"
              >
                “ChatGPT: are AI-generated cover letters the way forward?”
              </a>{" "}
              by Kim Cunningham for Welcome to the Jungle
            </li>
            <li>
              <a
                target="_blank"
                className="text-blue-600 hover:underline"
                href="https://www.openai.com/authentic-cover-letter"
                rel="noreferrer"
              >
                “Authentic” cover letter prompt for ChatGPT/generative AI
              </a>
            </li>
          </ul>
        </div>
        <h2 className="mb-2 mt-10 text-3xl font-bold text-[#0182A9]">
          “Authentic” cover letter prompt for ChatGPT/generative AI
        </h2>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          If you're having trouble writing your authentic cover letter, it's still possible to use
          ChatGPT or a similar AI writer assistant. The key is to feed the AI as much good
          information as possible and let it worry about the form, flow, and sentence structure.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Use this form as a ChatGPT/generative AI prompt. The more blanks you fill in, the better
          and more authentic your result. Blurt out the answers however they come to mind. Feel free
          to use short bullet points and sentence fragments.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Always carefully read the final output, make edits, and remove anything that is not a
          truthful representation of your work.
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <i>* = required</i>
        </div>
        <hr />
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>[BEGIN PROMPT INSTRUCTIONS]</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">
          Use the information provided below to craft a cover letter for the attached job opening.
          It MUST be an authentic representation of the info provided, however you do not need to
          use all of the information provided. Begin with “Dear Hiring Manager,”
        </div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          Writing style: The tone of the cover letter should be personable and plain-spoken. Format
          the cover letter to be easy to read. Keep the language informal yet professional.{" "}
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">
          <strong>[END PROMPT INSTRUCTIONS]</strong>
        </div>
        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>[BEGIN PROMPT INPUTS]</strong>
        </div>

        <div className="text-lg font-normal leading-normal text-[#3F444F]">
          <strong>INTRO SECTION: What is the job title?*</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>INTRO SECTION: What is the company name?</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>INTRO SECTION: Where did you find the job?*</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: Share any experience with the company or their core product(s)
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: Share what you learned while researching or trying the company’s
            product(s) for the first time
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: Relationships or familiarity with key people within the company
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: Pick out something from the company's employment reviews that excites you
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: Share your experience with or reaction to the company's stated mission
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: Demonstrate understanding of some company or department challenge(s),
            even if it's just an educated guess
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            INTRO SECTION: List the top reasons you want this job more than other jobs
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            BODY SECTION: Resume Accomplishment 1 and reasons you were able to achieve it
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            BODY SECTION: Resume Accomplishment 2 and reasons you were able to achieve it
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            BODY SECTION: Resume Accomplishment 3 and reasons you were able to achieve it
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            BODY SECTION, ADDITIONAL PROMPT INSTRUCTIONS: Use the job ad below to identify the top 3
            job responsibilities. Use the resume below to map multiple of my jobs to each of the top
            3 job responsibilities from the job ad and include it in the resume.
          </strong>
        </div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>BODY SECTION: Describe your working style for this type of job</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            BODY SECTION: What are your work superpowers? What are the little things you do better
            than others in your role?
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>
            BODY SECTION: Other achievements or professional anecdotes not on your resume
          </strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>CONCLUSION SECTION: Relevant Links</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>CONCLUSION SECTION: Name and Contact info*</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>JOB AD (Paste):*</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
          <strong>MY RESUME (Paste):*</strong>
        </div>
        <div className="text-lg font-normal leading-normal text-[#3F444F]">INPUT:</div>

        <div className="text-lg font-normal leading-normal text-[#3F444F]">
          <strong>[END PROMPT INPUTS]</strong>
        </div>
      </div>
    </div>
  );
};

export default Blog1Section;
