import { Trans } from "@lingui/macro";
import { List } from "@phosphor-icons/react"; // Importing the hamburger icon
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "./buttons/button-kit";

const menuItemsSection1 = [
  { label: <Trans>Resume Builder</Trans>, href: "/dashboard/resumes" },
  { label: <Trans>Cover Letter</Trans>, href: "/dashboard/cover-letters" },
  { label: <Trans>Personality Snapshot</Trans>, href: "/dashboard/personality-snapshots" },
  { label: <Trans>Assessments</Trans>, href: "/dashboard/assessments" },
  { label: <Trans>Blog</Trans>, href: "/blog/how-to-write-a-truly-authentic-cover-letter" },
  { label: <Trans>About</Trans>, href: "/about-us" },
];

const menuItemsSection2 = [{ label: <Trans>Log In</Trans>, href: "/auth/login" }];

const MenuItems = ({ isMobile }: { isMobile: boolean }) => (
  <>
    {/* First Section of Menu Items */}
    <div className={`flex ${isMobile ? "flex-col space-y-2" : "space-x-4"}`}>
      {menuItemsSection1.map((item) => (
        <a
          key={item.href}
          href={item.href}
          className={`min-w-fit leading-10 text-white hover:text-gray-200 ${isMobile ? "text-lg" : ""}`} // Make text bigger for mobile
        >
          {item.label}
        </a>
      ))}
    </div>
  </>
);

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/"); // Navigates to the home page
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#0182A9] p-4">
      <div className="flex items-center justify-between">
        {/* Left Section: Logo and Menu Items */}
        <div className="flex items-center space-x-10">
          {/* Logo */}
          <div></div>
          <div className="flex min-w-fit cursor-pointer items-center" onClick={handleLogoClick}>
            <img src={"/landing/workinglife.svg"} alt="Logo" className="h-8" />
          </div>

          {/* Desktop Menu Items */}
          <div className="hidden md:flex">
            <MenuItems isMobile={false} />
          </div>
        </div>

        {/* Right Section: Hamburger Menu Icon for Mobile and Log In Button */}
        <div className="flex items-center">
          {/* Hamburger Menu Icon for Mobile */}
          <div className="md:hidden">
            <button className="text-white" onClick={toggleMenu}>
              <List size={24} />
            </button>
          </div>

          {/* Log In Button for Desktop */}
          <div className="hidden items-center space-x-4 md:flex">
            {menuItemsSection2.map((item) => (
              <a
                key={item.href}
                href={item.href}
                className={`min-w-fit leading-10 text-white hover:text-gray-200`}
              >
                {item.label}
              </a>
            ))}
            <Button
              border
              borderColor="#E5E7EB"
              textColor="text-white"
              onClick={() => {
                navigate("/dashboard/resumes");
              }}
            >
              <Trans>Get Started</Trans>
            </Button>
            <div></div>
          </div>
        </div>
      </div>

      {/* Mobile Menu Items */}
      {isOpen && (
        <div className="mt-4 md:hidden">
          <div className="flex flex-col items-center space-y-2">
            <MenuItems isMobile={true} />
            <div style={{ marginLeft: "-120px" }} className="flex flex-col  space-y-2">
              {menuItemsSection2.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  className={`min-w-fit text-lg leading-10 text-white hover:text-gray-200`}
                >
                  {item.label}
                </a>
              ))}
            </div>
            <Button
              border
              borderColor="#E5E7EB"
              textColor="text-white"
              onClick={() => {
                console.log();
              }}
            >
              <Trans>Get Started</Trans>
            </Button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
